//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrial } from '@/views/fileStream/documentManage/minxins'
import { corpId, userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import processMess from '@/views/fileStream/documentSend/components/process.vue'
import moment from 'moment'
import editor from './editor'
import myform from './form.vue'
import mytable from './table.vue'
import upload from './upload'
import { documentTabDefaultByType } from '@/api/modular/fileStream/processingSignature'
export default {
  components: { mytable, myform, upload, editor, processMess },
  mixins: [retrial],
  props: {
    officialId: {
      type: String,
      default: '',
    },
    todoType: {
      type: String,
      default: 'registration',
    },
    randomCode: 0,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    editType: {
      type: '',
      default: '',
    },
  },
  data() {
    return {
      fileIds: [],
      content: '',
      info: {},
      moment,
    }
  },

  methods: {
    setDetail(data) {
      this.info = data
      const { content, documentFileList } = data
      this.content = content
      this.fileIds = documentFileList
    },
    // 清空頁面緩存值
    resetDetail() {
      this.$refs.mytable && this.$refs.mytable.resetFormInfo()
      this.$refs.myForm && this.$refs.myForm.resetList()
      documentTabDefaultByType(1)
      .then(res => {
        if (res.code === 200) {
          this.info = {
            name: res.data,
            fileIds: [],
            attach: [],
            draftId: [
              {
                type: 'userName',
                corpId,
                userId
              }
            ]
          }
        }
      })
      this.content = ''
      this.fileIds = []
    },
    changeStatus(evt) {
      this.$emit('changeStatus', evt)
    },
    close() {
      this.$emit('close')
    },
    //提交續審數據
    submitRenewalData() {
      const values = this.getFormObj()
      const obj = {
        acceptTime: values?.lwsj ? moment(values?.lwsj).format('YYYY-MM-DD') : null,
        name: values?.name,
        urgencyDegree: Number(values?.jjcd),
        secrecyDegree: Number(values?.miji),
        deviseOpinion: values?.nbyj,
        // approvalWay: Number(values.spfs),
        receiverCompany: values?.receiverCompany,
        draftId: this.filter(values?.draftId),
        // tellPhone: values?.tellPhone,
        summary: values?.summary,
        documentType: 1,
        documentNumber: values?.documentNumber,
        title: values?.wjbt,
        attach: values?.attach && values?.attach[0],
        fileIds: values?.fileIds,
        status: 2,
        templateId: values?.templateId,
        id: values?.id,
      }
      this.handleSubmit(obj)
    },
  },
}
