//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Ellipsis, FullModelView } from '@/components'
import { mapState } from 'vuex'
import formBox from './fromBox.vue'
export default {
  name: 'StepsForm',
  components: {
    Ellipsis,
    FullModelView,
    formBox,
  },
  computed: {
    ...mapState({
      visible: (state) => state.bill.registration,
    }),
  },
  data() {
    return {
      advanced: false,
      orderStatusData: [],
      buyingScheduleData: [],
      productionScheduleData: [],
      title: '新增',
      subTitle: '收文登記',
      todoType: 'registration',
      // visible: false,
      current: 0,
      officialId: '',
      stepList: [
        { title: '填寫', desc: '項目信息' },
        { title: '上傳', desc: '項目附件' },
        { title: '進度', desc: '項目進度' },
        { title: '售後', desc: '項目售後' },
        { title: '反饋', desc: '項目備註' },
        { title: '開票', desc: '項目發票' },
        { title: '評價', desc: '客戶評價' },
      ],
      project: null,
      submitLoading: false,
      projectChange: false,
      isDisabled: false, // 是否可編輯
      randomCode: null, // 亂碼 保證每次進入請求最新數據
      editType: '', //修改類型
    }
  },
  methods: {
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    changeStatus({ officialId, todoType }) {
      this.officialId = officialId
      this.todoType = todoType
      this.title = '發起審批'
    },
    open({ type, id, current = 0 }) {
      let randomCode = Math.floor(Math.random() * 1024 * 1024 * 1024)
      this.editType = null
      this.isDisabled = false
      if (type === 'edit') {
        this.randomCode = randomCode
        this.title = '編輯'
        this.current = current
        this.project = null
        this.officialId = id
        this.todoType = 'registration'
      } else if (type === 'approval') {
        this.randomCode = randomCode
        this.officialId = id
        this.title = '發起審批'
        this.todoType = 'registration'
        // this.isDisabled = true
      } else if (type === 'add') {
        this.project = null
        this.current = current
        this.title = '新增'
        this.todoType = 'registration'
        this.officialId = ''
        this.$refs.box.resetDetail()
        // this.$refs.box.fileIds = [] // 清空當前上傳的文件目錄
      } else if (type === 'renewal') {
        // 續審
        this.randomCode = randomCode
        this.title = '修改續審'
        this.current = current
        this.project = null
        this.officialId = id
        this.todoType = 'registration'
        this.editType = 'renewal'
      } else if (type === 'retrial') {
        // 重審
        this.randomCode = randomCode
        this.title = '修改重審'
        this.current = current
        this.project = null
        this.officialId = id
        this.todoType = 'registration'
      }
      this.$store.commit('bill/setRegistration', true)
    },
    close() {
      console.log('close=============')
      this.$store.commit('bill/setRegistration', false)
      this.$emit('refresh')
    },
    handleCancel() {
      console.log('handleCancel====================')
      this.projectChange = false
      this.$store.commit('bill/setRegistration', false)
      this.advanced = false
      this.current = 0
      this.project = null
      this.$emit('ok')
    },
  },
}
